<template lang="html">

  <div class="usluga">



  <div class="headUsluga">

<router-link class="navbar-brand" to="/ksuslugeEng">Back </router-link>
  <h3>Executive Search</h3>
  <p>Executive Search is a service by which, using direct access and an overview of a rich database of candidates, we find professional candidates whose competencies meet the needs of clients and the conditions for a particular position.</p>
  <h5>You need these services? Fill the form below:</h5>

  </div>
  <div class="card-body">

       <!-- @submit.prevent="registerUser"  -->
      <div class="form-group">
        <label for="companyName">Company name<p style="display:inline;color:red">*</p></label>
        <input
          class="form-control"
          type="text"
          placeholder=""
          name="companyName"
          v-model="companyName"
          id="companyName"
          >
      </div>
      <div class="form-group">
        <label for="contactName">Full name of contact person<p style="display:inline;color:red">*</p></label>
        <input
          class="form-control"
          type="text"
          placeholder=""
          name="contactName"
          v-model="contactName"
          id="contactName"
          >
      </div>
      <div class="form-group">
        <label for="contactNumber">Phone number<p style="display:inline;color:red">*</p></label>
        <input
          class="form-control"
          type="text"
          placeholder=""
          name="contactNumber"
          v-model="contactNumber"
          id="contactNumber"
          >
      </div>
      <div class="form-group">
        <label for="email">E-mail<p style="display:inline;color:red">*</p></label>
        <input
          class="form-control"
          type="text"
          placeholder=""
          name="email"
          v-model="email"
          id="email"
          >
      </div>
      <div class="form-group">
        <label for="positionName">Name of workplace<p style="display:inline;color:red">*</p></label>
        <input
          class="form-control"
          type="text"
          placeholder=""
          name="positionName"
          v-model="positionName"
          id="positionName"
          >
      </div>
      <div class="form-group">
        <label for="description">Description of workplace</label>
        <input
          class="form-control"
          type="text"
          placeholder=""
          name="description"
          v-model="description"
          id="description"
          >
      </div>
      <div class="form-group">
        <label for="numberOfExecutors">Number of required executives</label>
        <input
          class="form-control"
          type="text"
          placeholder=""
          name="numberOfExecutors"
          v-model="numberOfExecutors"
          id="numberOfExecutors"
          >
      </div>
      <div class="form-group">
        <label for="pay">Estimated pay (Optional)</label>
        <input
          class="form-control"
          type="text"
          placeholder=""
          name="pay"
          v-model="pay"
          id="pay"
          >
      </div>

      <div class="validation">
        {{validation}}
      </div>
      <button class="btn btn-primary" @click="submit">Submit</button>
      &nbsp;&nbsp;&nbsp;&nbsp;


</div>
</div>

</template>

<script>

// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
export default {

  computed: mapGetters(["user"]),
  name: 'ExecutiveSearchEng',
  components: {

  },
  data(){
    return{
      companyName:"",
  contactName:"",
  contactNumber:"",
  email:"",
  positionName:"",
  description:"",
  numberOfExecutors:"",
  validation:"",
  date:"",
    }
  },
  methods:{
  ...mapActions(['submitServiceAplication']),
  submit(){

    this.validation="";

    if(this.companyName==""){
      this.validation="Unesite naziv kompanije."
    }
    if(this.contactName==""){
      this.validation="Unesite ime i prezime kontakt osobe."
    }
    if(this.email==""){
      this.validation="Unesite email."
    }
    if(this.contactNumber==""){
      this.validation="Unesite kontakt telefon."
    }
    if(this.positionName==""){
      this.validation="Unesite naziv pozicije."
    }
    const d = new Date();
    let day = d.getDate();
    let month = d.getMonth()+1;
    let year = d.getFullYear();
    this.date= "Datum: "+day+"."+month+"."+year+".";


    let text = [];
    text.push("EXECUTIVE SEARCH");
    text.push(this.date);
    text.push("Naziv Kompanije: "+this.companyName);
    text.push("Ime i Prezime Kontakt Osobe: "+this.contactName);
    text.push("Email: "+this.email);
    text.push("Opis Posla: "+this.description);
    text.push("Broj potrebnih izvršilaca: "+this.numberOfExecutors);
    text.push("Ime Pozicije: "+this.positionName);

    text.push("Okvirna zarada (Opciono): "+this.pay);

    let data ={
      formular:text
    }

    if(this.validation==""){
    this.submitServiceAplication(data);
    this.validation="Uspesno poslat formular."
    }
  }
  },
  beforeCreate: function() {
        document.body.className = 'usluge';
  },

}
</script>

<style lang="css" scoped>
.usluga{
  position: absolute;
  top: auto;
  left: 10%;
  right: 10%;
  background-color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin-top: 15px;
}
.headUsluga{
  background-color: #77bbee;
  padding: 10px;
  border-top-left-radius:  10px;
  border-top-right-radius:  10px;
}
.card-body{
  background-color: #bbffff;
  border-bottom-left-radius:  10px;
  border-bottom-right-radius:  10px;
}
.validation{
  margin-top: 5px;
  background-color: #00aaaa;
}
</style>
